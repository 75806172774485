import React from "react";
import Page from "./../components/Page";
import Section from "./../components/Section";
import ButtonLink from "./../components/ButtonLink";
import SEO from "./../components/SEO";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

function CommunityPage() {
  return (
    <Page>
      <SEO title="Community | OHIF" />
      <main className="pt-16 bg-blue-800">
        <div className="lg:grid lg:grid-cols-12">
          {/* INTRO */}
          <div className="xl:col-span-2 lg:col-span-1"></div>
          <div className="phone-size:main-padding-xs md:main-padding-md lg:main-padding-lg xl:col-span-8 lg:col-span-10">
            <Section>
              <h1 className="phone-size:h1-heading-ps lg:h1-heading-lg">
                Assets
              </h1>
              <p className="phone-size:section-content-ps lg:section-content-lg mt-12">
                You are free to download and use the following assets in your
                own projects/presentations etc.
              </p>

              <div className="text-blue-100 text-xl flex gap-8 mt-4 ">
                <div>
                  <h3>OHIF Logo Dark</h3>
                  <StaticImage
                    src="../images/ohif-logo-dark.png"
                    alt="OHIF Logo Dark"
                    className="mt-2"
                  />
                </div>

                <div>
                  <h3>OHIF Logo Light</h3>
                  <StaticImage
                    src="../images/ohif-logo-light.png"
                    className="mt-2"
                    alt="OHIF Logo Light"
                  />
                </div>
              </div>
            </Section>
          </div>
          <div className="xl:col-span-2 lg:col-span-1"></div>
        </div>
      </main>
    </Page>
  );
}

export default CommunityPage;
